#Nav {
  background: #006633;
  height: 89vh;
  min-width: 50px !important;

  .topSide {
    position: relative;

    .hamBurgerButton {
      position: absolute;
      top: 8%;
      right: -18px;

      @media (max-width: 1126px) {
        right: -14px;
      }

      @media (max-width: 992px) {
        right: -16px;
      }

      span {
        border-radius: 8px;
        background: var(--Dark-green, #003000);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 38px;
        height: 34px;

        @media (max-width: 1126px) {
          width: 30px;
          height: 28px;
        }

        @media (max-width: 991px) {
          width: 38px;
          height: 34px;
        }

        @media (max-width: 768px) {
          visibility: hidden;
        }

        @media (max-width: 610px) {
          visibility: hidden;
        }
      }

      @media (max-width: 575px) {
        visibility: hidden;
      }
    }

    #user {
      @media (max-width: 576px) {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      img {
        width: 65px;

        @media (max-width: 992px) {
          width: 60px;
        }

        @media (max-width: 856px) {
          width: 55px;
        }

        @media (max-width: 768px) {
          width: 55px;
        }

        @media (max-width: 593px) {
          width: 55px;
        }
      }

      .userInfo {
        padding-left: 10px;

        @media (max-width: 575px) {
          display: none;
        }

        p {
          &:nth-child(1) {
            color: rgba(255, 255, 255, 0.75);
            font-size: 10px;
            font-style: normal;
            font-weight: 450;
            line-height: 12px;
            letter-spacing: 0.4px;
            text-transform: uppercase;
          }

          &:nth-child(2) {
            color: #fff;
            font-size: 14px;
            font-style: normal;
            font-weight: 450;
            line-height: 20px;
          }
        }
      }
    }

    .navItems {
      overflow-x: hidden;
      width: 100%;

      @media (max-width: 575px) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      .list {
        padding: 10px 10px;
        margin: 10px 10px;
        display: flex;
        align-items: center;
        color: #bfcbbf;

        .ShowOpacity {
          opacity: 1;
        }

        .HideOpacity {
          opacity: 0;
        }

        &:hover {
          background-color: #003000;
          border-radius: 10px;
          cursor: pointer;
          color: #ffff;
          font-weight: bolder;
          transition: 0.4s ease-in all !important;
        }

        main {
          @media (min-width: 992px) and (max-width: 1069px) {
            font-size: 14px;
          }

          @media (max-width: 575px) {
            display: none !important;
          }
        }

        main,
        i > svg,
        .pro-icon > svg {
          @media (max-width: 720px) {
            font-size: 14px;
            width: 18px !important;
          }

          @media (max-width: 634px) {
            font-size: 11px;
            width: 18px !important;
          }

          @media (max-width: 575px) {
            width: 25px !important;
          }
        }
      }
    }
  }

  .bottomSide {
    .Help {
      padding: 10px 10px;
      margin: 10px 10px;
      display: flex;
      align-items: center;
      color: #bfcbbf;
      margin-bottom: 10px;

      &:hover {
        background-color: #003000;
        border-radius: 10px;
        cursor: pointer;
        color: #ffff;
        font-weight: bolder;
        transition: 0.5s ease-in all !important;
      }
    }
  }
}

.isActive {
  .list {
    background-color: #003000;
    border-radius: 10px;
    cursor: pointer;
    color: #ffff !important;
    font-weight: bolder;
    transition: 0.4s ease-in all !important;
  }
}
